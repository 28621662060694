import React, { useEffect, useMemo, useState } from 'react';
import Project from '../../../screens/project';

import {
  Form,
  Steps,
  Button,
  message,
  Spin,
  Select,
  Drawer,
  List,
  Card,
  Table,
  Modal,
  Tabs,
  Breadcrumb,
  theme,
  Divider,
  Tag,
  Tooltip,
  Row,
  Col,
  Badge,
  Input,
  Typography
} from 'antd';
import {
  LoadingOutlined,
  DashboardOutlined,
  EditOutlined,
  DisconnectOutlined,
  UnlockOutlined,
  LockOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { find, isString, isEmpty, isUndefined, keys, startCase, isNull } from 'lodash';
import ProjectDetailForm from './projectdetail';
import GHGRatingForm from './ghgrating';
import SDGRatingForm from './sdgrating';
import './main.css';
import ProjectAttributesForm from './projectattributes';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { updateProject, moveProject, getHistory, cloneProject, getRatingChanges } from '../../../api/project';
import { getGHGRatingOptions, getRatingOptions } from '../../../config/rating.config';
import dayjs from 'dayjs';
import { convertGHGRatingFromNumbertoText, convertSDGRatingFromNumbertoText } from '../../../utils';
import { useQueryClient } from 'react-query';
import { Option } from 'antd/es/mentions';
import CreditingPeriodForm from './creditingperiod';


const { Step } = Steps;

enum ProjectStatus {
  DRAFT = 'draft',
  STAGING = 'staging',
  PRODUCTION = 'production'
}

const STATUS_MAP = {
  draft: {
    label: 'Draft',
    color: 'blue',
    icon: <DisconnectOutlined />,
    tooltip: (
      <>
        <ul>
          <li>Draft projects are not visible on the platform.</li>
          <li>Ratings data is editable.</li>
          <li>Catcher template is locked.</li>
        </ul>
        <i>
          Note: These projects were marked as Visible_On_Web: Hide in the previous version of "The
          Sandbox"
        </i>
      </>
    )
  },
  staging: {
    label: 'Staging',
    color: 'orange',
    icon: <UnlockOutlined />,
    tooltip: (
      <>
        <ul>
          <li>Staging projects are visible on the platform.</li>
          <li>Ratings data is editable</li>
          <li>Catcher data is editable</li>
          <li>
            Changes to a project in Staging are visible on the platform only when the 'Staging' mode
            is enabled. This mode switching is only available for Internal/Calyx users.
          </li>
        </ul>
      </>
    )
  },
  production: {
    label: 'Production',
    color: 'green',
    icon: <LockOutlined />,
    tooltip: (
      <>
        <ul>
          <li>Production projects are visible on the platform.</li>
          <li>Ratings data is locked.</li>
          <li>Catcher data is locked.</li>
          <li>This is the data that is visible to all of our clients.</li>
        </ul>
      </>
    )
  }
};

interface HistoryChangesData {
  [category: string]: {
    [changeKey: string]: {
      old_value: any;
      new_value: any;
    };
  };
}

const ELIGIBLE_STATE_TRANSITIONS = {
  [ProjectStatus.DRAFT]: [ProjectStatus.STAGING],
  [ProjectStatus.STAGING]: [ProjectStatus.PRODUCTION, ProjectStatus.DRAFT],
  [ProjectStatus.PRODUCTION]: [ProjectStatus.STAGING, ProjectStatus.DRAFT]
};

const EditProjectComponent: React.FC<{ activeTab?: string }> = ({ activeTab }) => {
  const defaultActiveKey = useMemo(() => (activeTab === 'catcher' ? '2' : '1'), [activeTab]);
  const { id, creditingPeriodId } = useParams<{ id; creditingPeriodId: string }>();
  const client = useQueryClient();
  const [form] = Form.useForm();
  const [formcp] = Form.useForm();
  const [form1] = Form.useForm();
  const { step } = useParams<{ step: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const [parentData, setParentData] = useState<any>({});
  const [sdgParentData, setSDGParentData] = useState<any>({});
  const [allFormValues, setAllFormValues] = useState<any>({});
  const [showSteps, setShowSteps] = useState([true, false, false, false]);
  const [loading, setLoading] = useState(false);
  const [workflowState, setWorkflowState] = useState<ProjectStatus>(ProjectStatus.STAGING);
  const [historyChangesData, setHistoryChangesData] = useState<HistoryChangesData>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [draftOpen, setDraftOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dateType, setDateType] = useState<any>('date');

  const ratingOptions = getRatingOptions();
  const ghgRatingOptions = getGHGRatingOptions();

  const [cloneModalOpen, setCloneModalOpen] = useState(false);
  const [isOkDisabled, setIsOkDisabled] = useState(true);

  const handleClone = () => {
    setCloneModalOpen(true);
  };

  const handleCloneModalOk = async () => {
    try {
      const formData = formcp.getFieldsValue();
      await formcp.validateFields();
      try {
        setCloneModalOpen(false);
        setLoading(true);
        const response = await cloneProject({
          newProjectId: Number(formData.projectId),
          newGHGCode: formData.ghgStandard,
          creditingPeriodId: parentData.projectWorkflow.crediting_period_id
        });
        setLoading(false);
        message.success('Cloned the project successfully');
        setLoading(true);
        navigate(
          `/project/edit/${response.response?.id}/cp/${response.response?.project_crediting_periods?.[0].crediting_period_id}/step/0`,
          {
            replace: true
          }
        );
        await new Promise((resolve) => setTimeout(resolve, 500));
        window.location.reload();
      } catch (err: any) {
        console.log(`Error cloning project: ${err}`);
        setLoading(false);
        message.error({
          content: err.message || 'Unable to clone project',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      console.log('error', err);
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    const activeStep = Number(step);
    if (activeStep && activeStep > -1 && activeStep <= 4) {
      setCurrentStep(activeStep);
    }
  }, [step]);

  const updateParentData = async (data) => {
    try {
      setParentData(data);
      setWorkflowState(data.projectWorkflow.state);
    } catch (err) {
      console.log(`error occured in update project status function: ${err}`);
    }
  };

  const updateSDGParentData = (data) => {
    setSDGParentData(data);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };
  const updateProjectStatus = async (data) => {
    try {
      if (data === ProjectStatus.PRODUCTION) {
        if (workflowState === ProjectStatus.DRAFT) {
          message.error(`Move the project to staging first`);
          return;
        }
        const response = await updateProjectDetails(form.getFieldsValue(), currentStep);
        if (!(response instanceof Error)) {
          try {
            setLoading(true);
            const response = await getHistory(parentData.projectWorkflow.crediting_period_id);
            if (response.changes_found) {
              delete response.response.changes.project_crediting_period?.date_type;
              setHistoryChangesData(response.response.changes);
            }
            client.invalidateQueries(`project_${id}_cp_${creditingPeriodId}`);
            setLoading(false);
            setDrawerOpen(true);
          } catch (err) {
            setHistoryChangesData({});
            console.log(`error fetching project history: ${err}`);
            setLoading(false);
            message.error(`An error occured while fetching the project changes`);
          }
        }
      } else {
        if (parentData.project.is_live) {
          message.error('This project cannot be moved');
          return;
        }
        if (data === ProjectStatus.DRAFT) {
          setDraftOpen(true);
        } else {
          setLoading(true);
          const response = await moveProject({
            creditingPeriodId: parentData.projectWorkflow.crediting_period_id,
            workflowState: data
          });
          client.invalidateQueries(`project_${id}_cp_${creditingPeriodId}`);
          setLoading(false);
          setWorkflowState(data);
          message.success(`Moved the project to ${data} succesfully`);
        }
      }
    } catch (err) {
      console.log(`error in update project status function: ${err}`);
      setLoading(false);
    }
  };

  const nextStep = async (e) => {
    try {
      setAllFormValues({
        ...allFormValues,
        ...form.getFieldsValue()
      });
      await updateProjectDetails(form.getFieldsValue());
    } catch (error: any) {
      message.error({ content: error.message });
    }
  };

  const prevStep = (e) => {
    navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep - 1}`);
    setShowSteps((prevState) => prevState.map((value, index) => index === currentStep - 1));
    setCurrentStep(currentStep - 1);
  };

  const onFinish = async (values: any) => {
    await updateProjectDetails(form.getFieldsValue(), currentStep);
    navigate('/project');
  };

  const updateProjectDetails = async (formatData, clickStep?: number) => {
    let response;
    if (currentStep === 0) {
      response = await updateProjectDetail(formatData, clickStep);
    } else if (currentStep === 1) {
      response = await updateCreditingPeriod(formatData, clickStep);
    } else if (currentStep === 2) {
      response = await updateProjectAttributes(formatData, clickStep);
    } else if (currentStep === 3) {
      response = await updateGHGData(formatData, clickStep);
    } else if (currentStep === 4) {
      response = await updateSDGData(formatData, clickStep);
    }
    return response;
  };

  const moveToNextStep = async (clickStep?: number) => {
    if (clickStep !== undefined) {
      navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
      setCurrentStep(clickStep);
      setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
    } else {
      navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
      setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
      setCurrentStep(currentStep + 1);
    }
  };

  const updateProjectData = async (formData) => {
    const formatDate = (date) => dayjs.utc(date).format('YYYY-MM-DD');
    const data = {
      projectName: formData.projectName,
      about: formData.about,
      projectId: formData.projectId,
      ...(formData.projectParent && {
        projectParentId: find(parentData.projectNames, { name: formData.projectParent })?.id
      }),
      projectTypeId: find(
        parentData.projectTypes,
        (projectType) => formData.projectType === projectType.display_name
      )?.id,
      ghgStandardId: find(
        parentData.ghgStandards,
        (ghgStandard) => formData.ghgStandard === ghgStandard.code
      )?.id,
      ghgMethodologyId: find(
        parentData.ghgMethodologies,
        (ghgMethodology) => formData.ghgMethodology === ghgMethodology.display_name
      )?.id,
      projectStartDate: formatDate(formData.yearRange[0]),
      projectEndDate: formatDate(formData.yearRange[1]),
      ...(formData.dateType && {
        creditingPeriodDateType: formData.dateType
      }),
      ...(formData.creditingPeriodDuration?.[0] && {
        creditingPeriodStartDate: formatDate(formData.creditingPeriodDuration[0])
      }),
      ...(formData.creditingPeriodDuration?.[1] && {
        creditingPeriodEndDate: formatDate(formData.creditingPeriodDuration[1])
      }),
      ...(formData.creditingPeriodNarration && {
        creditingPeriodNarration: formData.creditingPeriodNarration
      }),
      ...(!isEmpty(formData.proponent) && {
        proponentIds: formData.proponent
      }),
      ...(formData.locationType === 'state' &&
        !isEmpty(formData.projectState) && {
        locationIds: formData.projectState
      }),
      ...(formData.locationType === 'country' &&
        formData.projectCountry && {
        locationIds: [formData.projectCountry]
      }),
      projectAttributes: {
        nature: !isUndefined(formData.nature) ? formData.nature : false,
        removal: !isUndefined(formData.removal) ? formData.removal : false,
        corsia: !isUndefined(formData.corsia) ? formData.corsia : false
      },
      onWatch: formData.onWatch,
      ...(formData.onWatchReason !== null && {
        onWatchReason: formData.onWatchReason
      }),
      ...(formData.ghgRiskRatingDate && {
        ghgRiskRatingDate: formatDate(formData.ghgRiskRatingDate)
      }),
      ...(formData.ghgRiskRatingNotes && {
        ghgRiskRatingNotes: formData.ghgRiskRatingNotes
      }),
      ...(isString(formData.additionality) && {
        additionality: formData.additionality
      }),
      ...(isString(formData.permanence) && {
        permanence: formData.permanence
      }),
      ...(isString(formData.overlappingClaims) && {
        overlappingClaims: formData.overlappingClaims
      }),
      ...(isString(formData.baseline) && {
        baseline: formData.baseline
      }),
      ...(isString(formData.projectEmissions) && {
        projectEmissions: formData.projectEmissions
      }),
      ...(!isUndefined(formData.manualKlugingScore) && {
        manualKlugingScore: formData.manualKlugingScore
      }),
      ...(isString(formData.leakage) && {
        leakage: formData.leakage
      }),
      ...(formData.sdgImpactRating && {
        sdgImpactRating: ratingOptions.find((option) => option.label === formData.sdgImpactRating)
          ?.value
      }),
      ...(formData.sdgImpactRatingNotes && {
        sdgImpactRatingNotes: formData.sdgImpactRatingNotes
      }),
      ...(formData.sdgCertificate && {
        sdgCertificateId: find(
          sdgParentData.sdgCertificates,
          (sdgCertificate) => formData.sdgCertificate === sdgCertificate.code
        )?.id
      })
    };
    const projectResponse = await updateProject(id, Number(creditingPeriodId), data);
    return projectResponse.response;
  };

  const updateProjectDetail = async (formData, clickStep?: number) => {
    try {
      const formatDate = (date) => dayjs.utc(date).format('YYYY-MM-DD');
      const data = {
        projectName: formData.projectName,
        about: formData.about,
        projectId: formData.projectId,
        ...(formData.projectParent && {
          projectParentId: find(parentData.projectNames, { name: formData.projectParent })?.id
        }),
        projectTypeId: find(
          parentData.projectTypes,
          (projectType) => formData.projectType === projectType.display_name
        )?.id,
        ghgStandardId: find(
          parentData.ghgStandards,
          (ghgStandard) => formData.ghgStandard === ghgStandard.code
        )?.id,
        ghgMethodologyId: find(
          parentData.ghgMethodologies,
          (ghgMethodology) => formData.ghgMethodology === ghgMethodology.display_name
        )?.id,
        ...(formData.yearRange?.[0] && {
          projectStartDate: formatDate(formData.yearRange[0])
        }),
        ...(formData.yearRange?.[1] && {
          projectEndDate: formatDate(formData.yearRange[1])
        }),
        ...(formData.creditingPeriodDuration?.[0] && {
          creditingPeriodStartDate: formatDate(formData.creditingPeriodDuration[0])
        }),
        ...(formData.creditingPeriodDuration?.[1] && {
          creditingPeriodEndDate: formatDate(formData.creditingPeriodDuration[1])
        }),
        ...(dateType && {
          creditingPeriodDateType: dateType
        }),
        ...(formData.creditingPeriodNarration && {
          creditingPeriodNarration: formData.creditingPeriodNarration
        })
      };
      const currentFormFields = keys(data);
      await form.validateFields(currentFormFields);
      try {
        setLoading(true);
        const projectData = await updateProject(id, Number(creditingPeriodId), data);
        setLoading(false);
        form.setFieldValue('isOverRide', projectData.response.is_override);
        if (clickStep !== undefined) {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
          setCurrentStep(clickStep);
          setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
        } else {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
          setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
          setCurrentStep(currentStep + 1);
        }
      } catch (err: any) {
        setLoading(false);
        message.error({
          content: err.message || 'Unable to update the project details',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const isRegistryVintageValidDuration = (registryVintages: any[]): boolean => {
    if (isEmpty(registryVintages)) {
      return true;
    }

    const formattedVintages = registryVintages.map(vintage => ({
      startDate: new Date(vintage.duration?.[0]),
      endDate: new Date(vintage.duration?.[1]),
    }));

    formattedVintages.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    for (let i = 0; i < formattedVintages.length - 1; i++) {
      const current = formattedVintages[i];
      const next = formattedVintages[i + 1];
      if (current.endDate.getTime() >= next.startDate.getTime()) {
        return false;
      }
    }

    return true;
  }

  const updateCreditingPeriod = async (formData, clickStep?: number) => {
    try {
      const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');
      await form.validateFields();
      const valid = isRegistryVintageValidDuration(formData.vintageYears);
      if (!valid) {
        message.error({
          content: 'Please ensure registry start and end dates don\'t overlap.',
          duration: 4,
          style: { textAlign: 'right' }
        });
        return;
      }
      const data = {
        ...(formData.creditingPeriodDuration?.[0] && {
          creditingPeriodStartDate: formatDate(formData.creditingPeriodDuration[0])
        }),
        ...(formData.creditingPeriodDuration?.[1] && {
          creditingPeriodEndDate: formatDate(formData.creditingPeriodDuration[1])
        }),
        ...(formData.creditingPeriodNarration && {
          creditingPeriodNarration: formData.creditingPeriodNarration
        }),
        ...(formData.verifiedCredits && {
          verifiedCredits: formData.verifiedCredits,
        }),
        ...(formData.vintageYears && {
          registryVintageYears: formData.vintageYears.map(vintageYear => ({
            year: dayjs(vintageYear.year).year(),
            ...(vintageYear.duration?.[0] && {
              startDate: formatDate(vintageYear.duration[0]),
            }),
            ...(vintageYear.duration?.[1] && {
              endDate: formatDate(vintageYear.duration[1]),
            }),
          })),
        }),
      };
      try {
        setLoading(true);
        const projectData = await updateProject(id, Number(creditingPeriodId), data);
        setLoading(false);
        if (clickStep !== undefined) {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
          setCurrentStep(clickStep);
          setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
        } else {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
          setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
          setCurrentStep(currentStep + 1);
        }
      } catch (err: any) {
        setLoading(false);
        message.error({
          content: err.message || 'Unable to update the crediting period details',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const updateProjectAttributes = async (formData, clickStep?: number) => {
    try {
      const data = {
        ...(formData.proponent && {
          proponentIds: formData.proponent
        }),
        ...(formData.locationType === 'state' &&
          formData.projectState && {
          locationIds: formData.projectState
        }),
        ...(formData.locationType === 'country' &&
          formData.projectCountry && {
          locationIds: formData.projectCountry ? [formData.projectCountry] : []
        }),
        projectAttributes: [
          {
            name: 'removal',
            value: form.getFieldValue(['removal', 'attribute_value']),
          },
          {
            name: 'removal_and_avoidance',
            value: form.getFieldValue(['removal_and_avoidance', 'attribute_value']),
          },
          {
            name: 'ccp',
            value: form.getFieldValue(['ccp', 'attribute_value']),
          },
          {
            name: 'corsia_pilot_phase',
            value: form.getFieldValue(['corsia_pilot_phase', 'attribute_value']),
            extraContent: form.getFieldValue(['corsia_pilot_phase', 'attribute_extra_content']),
          },
          {
            name: 'corsia_first_phase_pending',
            value: form.getFieldValue(['corsia_first_phase_pending', 'attribute_value']),
            extraContent: form.getFieldValue(['corsia_first_phase_pending', 'attribute_extra_content']),
          },
          {
            name: 'corsia_first_phase_approved',
            value: form.getFieldValue(['corsia_first_phase_approved', 'attribute_value']),
            extraContent: form.getFieldValue(['corsia_first_phase_approved', 'attribute_extra_content']),
          },
          {
            name: 'article_6_authorized',
            value: form.getFieldValue(['article_6_authorized', 'attribute_value']),
            extraContent: form.getFieldValue(['article_6_authorized', 'attribute_extra_content']),
          },
        ],
        onWatch: formData.onWatch,
        ...(formData.onWatchReason !== null && {
          onWatchReason: formData.onWatchReason
        })
      };
      const currentFormFields = keys(data);
      await form.validateFields(currentFormFields);
      try {
        setLoading(true);
        const projectData = await updateProject(id, Number(creditingPeriodId), data);
        const creditingPeriod = find(projectData.response.project_crediting_periods, {
          crediting_period_id: Number(creditingPeriodId)
        });
        setLoading(false);
        form.setFieldsValue({
          rightsizing: find(creditingPeriod.project_attributes, attribute => attribute.attribute.name === 'rightsizing'),
          overcreditingCategory: find(ratingOptions, {
            value: creditingPeriod.ratings.overcrediting_score
          })?.label,
          finalratingCategory: find(ghgRatingOptions, {
            value: creditingPeriod.ratings.ghg_risk_rating
          })?.label
        });
        setLoading(false);
        if (clickStep !== undefined) {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
          setCurrentStep(clickStep);
          setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
        } else {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
          setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        setLoading(false);
        message.error({
          content: 'Unable to update the project attributes',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const updateGHGData = async (formData, clickStep?: number) => {
    try {
      const formatDate = (date) => dayjs.utc(date).format('YYYY-MM-DD');
      const data = {
        ...(formData.ghgRiskRatingDate && {
          ghgRiskRatingDate: formatDate(formData.ghgRiskRatingDate)
        }),
        ...(formData.ghgRiskRatingNotes && {
          ghgRiskRatingNotes: formData.ghgRiskRatingNotes
        }),
        manualGHGRiskRatingScore: find(ghgRatingOptions, { label: formData.finalratingCategory })
          ?.value,
        ...(isString(formData.additionality) && {
          additionality: formData.additionality
        }),
        ...(isString(formData.permanence) && {
          permanence: formData.permanence
        }),
        ...(isString(formData.overlappingClaims) && {
          overlappingClaims: formData.overlappingClaims
        }),
        ...(isString(formData.baseline) && {
          baseline: formData.baseline
        }),
        ...(isString(formData.projectEmissions) && {
          projectEmissions: formData.projectEmissions
        }),
        ...(!isUndefined(formData.manualKlugingScore) && {
          manualKlugingScore: formData.manualKlugingScore
        }),
        ...(isString(formData.leakage) && {
          leakage: formData.leakage
        })
      };
      const currentFormFields = keys(data);
      await form.validateFields(currentFormFields);
      try {
        setLoading(true);
        const projectData = await updateProject(id, Number(creditingPeriodId), data);
        const creditingPeriod = find(projectData.response.project_crediting_periods, {
          crediting_period_id: Number(creditingPeriodId)
        });
        setLoading(false);
        form.setFieldsValue({
          rightsizing: find(creditingPeriod.project_attributes, attribute => attribute.attribute.name === 'rightsizing'),
          overcreditingCategory: find(ratingOptions, {
            value: creditingPeriod.ratings.overcrediting_score
          })?.label,
          finalratingCategory: find(ghgRatingOptions, {
            value: creditingPeriod.ratings.ghg_risk_rating
          })?.label
        });
        if (clickStep !== undefined) {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
          setCurrentStep(clickStep);
          setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
        } else {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
          setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        setLoading(false);
        message.error({
          content: 'Unable to update the ghg details',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      setLoading(false);
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const updateSDGData = async (formData, clickStep?: number) => {
    try {
      const data = {
        ...(formData.sdgImpactRating && {
          sdgImpactRating: ratingOptions.find((option) => option.label === formData.sdgImpactRating)
            ?.value
        }),
        ...(formData.sdgImpactRatingNotes && {
          sdgImpactRatingNotes: formData.sdgImpactRatingNotes
        }),
        ...(formData.sdgCertificate && {
          sdgCertificateId: find(
            sdgParentData.sdgCertificates,
            (sdgCertificate) => formData.sdgCertificate === sdgCertificate.code
          )?.id
        })
      };
      const currentFormFields = keys(data);
      await form.validateFields(currentFormFields);
      try {
        setLoading(true);
        const projectData = await updateProject(id, Number(creditingPeriodId), data);
        setLoading(false);
        if (clickStep !== undefined) {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${clickStep}`);
          setCurrentStep(clickStep);
          setShowSteps((prevState) => prevState.map((value, index) => index === clickStep));
        } else {
          navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep + 1}`);
          setShowSteps((prevState) => prevState.map((value, index) => index === currentStep + 1));
          setCurrentStep(currentStep + 1);
        }
      } catch (err) {
        setLoading(false);
        message.error({
          content: 'Unable to update the sdg details',
          duration: 4,
          style: { textAlign: 'right' }
        });
      }
    } catch (err) {
      message.error({
        content: 'Please fill in all required fields before proceeding.',
        duration: 4,
        style: { textAlign: 'right' }
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error('Form submission failed:', errorInfo);
  };

  const steps = [
    {
      title: 'Basic details',
      content: (
        <ProjectDetailForm
          form={form}
          updateParentDetails={updateParentData}
          dateType={dateType}
          setDateType={setDateType}
        />
      )
    },
    {
      title: 'Crediting Period Details',
      content: (
        <CreditingPeriodForm
          form={form}
        />
      )
    },
    { title: 'Attributes', content: <ProjectAttributesForm form={form} /> },
    { title: 'GHG', content: <GHGRatingForm form={form} workflowState={workflowState} /> },
    {
      title: 'SDG',
      content: <SDGRatingForm form={form} updateParentDetails={updateSDGParentData} />
    }
  ];

  const handleClickStep = async (step: number) => {
    if (workflowState === ProjectStatus.PRODUCTION || parentData.project?.is_live) {
      await moveToNextStep(step);
    } else {
      await updateProjectDetails(form.getFieldsValue(), step);
    }
  };

  const handleModalConfirm = async () => {
    try {
      let reason = form1.getFieldValue('reason');
      setModalOpen(false);
      setDrawerOpen(false);
      setLoading(true);
      const response = await moveProject({
        creditingPeriodId: parentData.projectWorkflow.crediting_period_id,
        workflowState: 'production',
        ...(reason && {
          ratingChangeReason: reason,
        }),
      });
      form1.setFieldValue('reason', '');
      setLoading(false);
      const apiResponse = response.response;
      if (apiResponse?.valid) {
        message.success('Published the project to production successfully');
        client.invalidateQueries(`project_${id}_cp_${creditingPeriodId}`);
        setIsOkDisabled(true);
        setWorkflowState(ProjectStatus.PRODUCTION);
        if (apiResponse?.new === true) {
          navigate(
            `/project/edit/${apiResponse.crediting_period.project_id}/cp/${apiResponse.crediting_period.crediting_period_id}/step/3`,
            {
              replace: true
            }
          );
          window.location.reload();
        }
      } else {
        Modal.error({
          title: (
            <h4 style={{ color: 'red', marginBottom: 0 }}>
              The project cannot be moved to production. Please fill in the below fields
            </h4>
          ),
          content: (
            <div style={{ color: 'black', marginTop: '16px', marginLeft: '16px' }}>
              {apiResponse.messages.map((msg, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <div style={{ marginRight: '8px', minWidth: '20px', textAlign: 'center' }}>
                    {index + 1}.
                  </div>
                  <div>{msg}</div>
                </div>
              ))}
            </div>
          )
        });
      }
    } catch (err) {
      console.log(`error in move project api: ${err}`);
      setLoading(false);
      message.error({
        content: 'Error occured, unable to publish the project to production',
        duration: 2,
        style: { textAlign: 'right' }
      });
    }
  };

  const handleDraftModalConfirm = async () => {
    try {
      setDraftOpen(false);
      setLoading(true);
      const response = await moveProject({
        creditingPeriodId: parentData.projectWorkflow.crediting_period_id,
        workflowState: ProjectStatus.DRAFT
      });
      client.invalidateQueries(`project_${id}_cp_${creditingPeriodId}`);
      setLoading(false);
      setWorkflowState(ProjectStatus.DRAFT);
      message.success(`Moved the project to ${ProjectStatus.DRAFT} succesfully`);
    } catch (err) {
      console.log(`error in move project api: ${err}`);
      setLoading(false);
      message.error({
        content: 'Error occured, unable to move project to draft',
        duration: 2,
        style: { textAlign: 'right' }
      });
    }
  };

  const handleChangeTab = (key) => {
    if (key === 'ratings') {
      navigate(`/project/edit/${id}/cp/${creditingPeriodId}/step/${currentStep}`);
    } else {
      navigate(`/project/${id}/cp/${creditingPeriodId}`);
    }
  };

  const handleReasonChange = () => {
    const reason = form1.getFieldValue('reason');
    const disabled = !isEmpty(historyChangesData.project_rating?.ghg_risk_rating) && reason === '' ? true : false;
    setIsOkDisabled(disabled);
  };

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <div>
      <div className="flex gap-4 items-center justify-between px-3 py-4">
        <div className="flex items-center gap-x-4">
          <span>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/project">All Projects</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/project/${id}/cp/${creditingPeriodId}`}>
                  {parentData?.project?.ghg_standard?.code +
                    parentData?.project?.project_id +
                    ': ' +
                    parentData?.project?.name}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </span>
          <Tooltip title={STATUS_MAP[workflowState].tooltip}>
            <Tag color={STATUS_MAP[workflowState].color}>{STATUS_MAP[workflowState].label}</Tag>
          </Tooltip>
        </div>
        <Modal
          title="Move to Draft"
          open={draftOpen}
          onOk={handleDraftModalConfirm}
          okText="Yes, hide"
          onCancel={() => setDraftOpen(false)}
          style={{ top: '20%' }}>
          <p>Hide this project on the platform. Are you sure you want to proceed? </p>
        </Modal>
        <Modal
          title="Clone Project"
          open={cloneModalOpen}
          onOk={handleCloneModalOk}
          onCancel={() => setCloneModalOpen(false)}
          style={{ top: '20%' }}>
          <div>
              <p>This creates a new project with the information mentioned below (Registry + New Project ID) and copies over the current project’s metadata + attributes + ratings + catcher data over to the new project. This feature is mainly targeted for Cookstoves team to help them create multiple VPAs easily</p>
          </div>
          <Form form={formcp} layout="vertical">
            <div className="flex gap-2">
              <Form.Item
                label="Registry"
                name="ghgStandard"
                rules={[{ required: true, message: 'Please select GHG Standard!' }]}>
                <Select>
                  {parentData?.ghgStandards?.map((standard) => (
                    <Option key={standard.id} value={standard.code}>
                      {standard.code}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="New Project ID"
                name="projectId"
                rules={[{ required: true, message: 'Please input Project ID!' }]}>
                <Input type="number" />
              </Form.Item>
            </div>
          </Form>
        </Modal>
        <div className="flex items-center gap-2 mr-2">
          {!parentData.project?.is_live && (
            <div className="flex gap-2 justify-end mr-4 items-center">
              {ELIGIBLE_STATE_TRANSITIONS[workflowState].map((state, index) => {
                return (
                  <Button
                    key={index}
                    type="default"
                    icon={STATUS_MAP[state].icon}
                    color={STATUS_MAP[state].color}
                    onClick={() => updateProjectStatus(state)}>
                    Move to {STATUS_MAP[state].label}
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <Tabs
        items={[
          {
            key: 'ratings',
            label: `Ratings`,
            //@ts-ignore
            icon: <DashboardOutlined />,
            children: (
              <div>
                <Spin
                  spinning={loading}
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />}
                  fullscreen
                />
                <Drawer
                  title="Recent Project Modifications"
                  placement="right"
                  closeIcon={true}
                  width={600}
                  onClose={onClose}
                  open={drawerOpen}
                  style={{ padding: '5px' }}>
                  {!isEmpty(historyChangesData) ? (
                    <>
                      <h3
                        style={{
                          textAlign: 'center',
                          margin: '0',
                          marginBottom: '20px',
                          color: '#0077be'
                        }}>
                        Please review below changes
                      </h3>
                      <List
                        dataSource={Object.entries(historyChangesData)}
                        renderItem={([category, changes]) => (
                          <>
                            {!isEmpty(changes) && (
                              <div>
                                <List.Item>
                                  <List.Item.Meta
                                    title={
                                      <strong style={{ fontSize: '20px', color: '#ff69b4' }}>
                                        {startCase(category)}
                                      </strong>
                                    }
                                  />
                                </List.Item>
                                {Object.entries(changes).map(([changeKey, changeValue], index) => (
                                  <List.Item key={index} style={{ marginLeft: '20px' }}>
                                    <List.Item.Meta
                                      title={
                                        <strong style={{ fontSize: '16px', color: '#4169e1' }}>
                                          {startCase(changeKey)}
                                        </strong>
                                      }
                                      description={
                                        <div>
                                          <div>
                                            <strong style={{ marginLeft: '30px', color: 'black' }}>
                                              Old Value:
                                            </strong>
                                            <span style={{ color: 'red', marginLeft: '10px' }}>
                                              {changeKey === 'ghg_risk_rating'
                                                ? ghgRatingOptions.find(
                                                  (option) =>
                                                    option.value ===
                                                    Number(changeValue?.old_value)
                                                )?.label
                                                : changeValue?.old_value?.toString()}
                                            </span>
                                          </div>
                                          <div>
                                            <strong style={{ marginLeft: '30px', color: 'black' }}>
                                              New Value:
                                            </strong>
                                            <span style={{ color: 'green', marginLeft: '10px' }}>
                                              {changeKey === 'ghg_risk_rating'
                                                ? ghgRatingOptions.find(
                                                  (option) =>
                                                    option.value ===
                                                    Number(changeValue?.new_value)
                                                )?.label
                                                : changeValue?.new_value?.toString()}
                                            </span>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </List.Item>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      />
                    </>
                  ) : (
                    <div style={{ textAlign: 'center', margin: '0' }}>
                      <h3 style={{ color: '#777', margin: '0' }}>No Changes Found</h3>
                    </div>
                  )}
                  <Modal
                    title="Confirm"
                    open={modalOpen}
                    onOk={handleModalConfirm}
                    okButtonProps={{disabled: !isEmpty(historyChangesData.project_rating?.ghg_risk_rating) && isOkDisabled}}
                    onCancel={() => setModalOpen(false)}
                    style={{ top: '20%' }}>
                    {isEmpty(historyChangesData.project_rating?.ghg_risk_rating) && (
                      <p style={{ textAlign: 'center', marginBottom: '0' }}>
                        Are you sure you want to proceed?
                      </p>
                    )}
                    {!isEmpty(historyChangesData.project_rating?.ghg_risk_rating) && (
                       <div>
                       <Typography.Paragraph>
                         <h5 style={{textAlign: 'left', paddingTop:'3%'}}>It looks like you have updated this project's rating. Please add a rating change entry in the Review & Rating Timeline section, so that the rationale for the change can be shown to users on the platform.</h5>
                       </Typography.Paragraph>
                       <Form
                            form={form1}
                            layout="vertical"
                            initialValues={{ reason: '' }}
                            onFinish={handleModalConfirm}
                          >
                            <Form.Item
                              name="reason"
                              rules={[{ required: true, message: 'Please provide a reason for the rating change.' }]}
                            >
                              <Input.TextArea
                                onChange={handleReasonChange}
                                rows={4}
                                placeholder="Enter your reason here"
                              />
                            </Form.Item>
                          </Form>
                     </div>
                    )}
                  </Modal>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button type="primary" onClick={() => setModalOpen(true)}>
                      Publish Project
                    </Button>
                  </div>
                </Drawer>
                <div className="py-4 px-5">
                  <Row justify="center">
                    <Col xxl={{ span: 16 }} md={{ span: 20 }} sm={{ span: 24 }}>
                      <Steps current={currentStep} onChange={handleClickStep} className="mb-5">
                        {steps.map((step, index) => (
                          <Step key={index} title={step.title} />
                        ))}
                      </Steps>
                      <Form
                        form={form}
                        disabled={workflowState === ProjectStatus.PRODUCTION}
                        name="updateProjectForm"
                        onFinish={onFinish}
                        size="middle"
                        onFinishFailed={onFinishFailed}
                        layout="vertical">
                        <div className="border border-solid border-gray-200 p-4 bg-white">
                          {currentStep === 0 && (
                            <ProjectDetailForm
                              form={form}
                              updateParentDetails={updateParentData}
                              dateType={dateType}
                              setDateType={setDateType}
                            />
                          )}

                          {currentStep === 1 && <CreditingPeriodForm form={form} />}

                          {currentStep === 2 && <ProjectAttributesForm form={form} />}

                          {currentStep === 3 && (
                            <GHGRatingForm form={form} workflowState={workflowState} />
                          )}

                          {currentStep === 4 && (
                            <SDGRatingForm form={form} updateParentDetails={updateSDGParentData} />
                          )}
                        </div>
                        <div className="flex gap-6 mt-3">
                          <Button
                            type="primary"
                            hidden={currentStep >= steps.length - 1}
                            onClick={nextStep}>
                            Next
                          </Button>
                          <Button
                            type="primary"
                            hidden={currentStep !== steps.length - 1}
                            htmlType="submit">
                            Submit
                          </Button>
                          <Button type="default" hidden={currentStep === 0} onClick={prevStep}>
                            Back
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            )
          },
          {
            key: 'catcher',
            label: `Catcher`,
            //@ts-ignore
            icon: <EditOutlined />,
            children: <Project />
          }
        ]}
        defaultActiveKey={defaultActiveKey}
        activeKey={activeTab}
        tabBarExtraContent={{
          left: <div className="ml-4" />,
          right: (
            <div className="mr-4 flex">
              <Button
                type="default"
                disabled={workflowState === 'production'}
                icon={<PlusCircleOutlined />}
                onClick={handleClone}>
                Clone Project
              </Button>
            </div>
          )
        }}
        type="card"
        size="large"
        className="my-2"
        onChange={handleChangeTab}
      />
    </div>
  );
};

export default EditProjectComponent;
